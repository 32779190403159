.employee {
	&-header {
		display: flex;
		align-items: center;
		gap: 2rem;
		justify-content: space-between;
		padding: 1.5rem;
		&__buttons {
			display: flex;
			gap: 10px;
			justify-content: end;
		}
		&-item {
			width: 30rem;
		}
	}
}
