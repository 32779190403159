.employeeApprovalContainer {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	&--checkbox {
		min-height: calc(100vh - 280px) !important;
		max-height: calc(100vh - 400px) !important;
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.buttonsContainer {
	// margin-top: 100px;
	margin-top: auto;
	align-self: flex-start;
}
